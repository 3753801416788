.performance-lists{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;

}

.performance-lists>p{
font-size: 0.9rem;
color: #f3f5da;
margin: 0.5rem
}

