.navbar{
    background: rgb(30, 30, 30);
    height: 7rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}
.logo{
    object-fit: cover;
    height: 6rem;
    width: 6rem;
   margin-top: 10px;
}
.desktop-menu-list{
    margin: 1rem;
    cursor: pointer;
}
.desktop-menu-list:hover{
    color: yellow;
    padding-bottom: .5rem;
    border-bottom: 3px solid yellow;
}


.desktop-menu-btn{
    border: none;
    background: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;  
    border-radius: 1rem;
}

.contact-icon{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
   margin: 0.5rem
}
.active{
    color: yellow;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid yellow;
  }

  .hamburger-menu{
    display: none;
    object-fit: cover;
    color: yellow;
    height: 4rem;
    width: 4rem;
   margin-top: 10px;

  }

  .mobile-menu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index:2;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40, 40, 40);
    border-radius: 1rem
  }

  .mobile-menu-list{
    color: white;
    padding: 0.5rem 3rem;
    margin: .25rem;
    background: rgb(30, 30, 30);
    border-radius: 1rem

  }


  @media (max-width: 680px){
    .hamburger-menu{
        display: flex;
    }
    .desktop-menu{
        display: none;
    }
  }