#intro {
  height: auto;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: -1;
  object-fit: cover;
  width: inherit;
height: 90%;
  opacity: .5;
}
.intro-content {
  font-size: 3rem;
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}
.intro-name {
  color: yellow;
}
.intro-para {
  font-size: small;
  font-weight: 100;
  letter-spacing: 1px;
}

.intro-btn {
  background: white;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
}

.intro-icon {
  object-fit: cover;
  margin: 0 0.5rem;
  height: 1rem;
}



@media screen and (max-width: 926px) {
  .intro-txt {
    font-size: 2rem;
  }
  .bg {
    right: 0vw;
   
  }

 
}

@media screen and (max-width: 840px) {
  .bg {
    right: 0vw;
    margin-right: 1rem;
    height: 50rem;
    padding-bottom: 3rem;
  }

  .intro-content {
    font-size: 3vw;
    
  }
  .hello {
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 680px) {
  .bg{
    right: -4vw;
    width: 130vw;
    height: 50rem;
    padding-left: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .bg {
    right: -5vw;
  
    margin-left: 2vw;
  }
}

@media screen and (max-width:415px) {
   
    .intro-content {
      margin-top: 2rem;
        font-size: 3vw;
        height: 28rem;
      }
   
    .intro-txt {
        font-size: 1rem;
        margin:.2rem;
      }

      .intro-para {
        font-size: vw;
        font-weight: 100;
        letter-spacing: .5px;
      }

      .bg {
        top: 15vw;
        right: -4vw;
        width: 120vw;
       height: 30rem;
       padding-left: 10rem;
       
      }
}


@media screen and (max-width: 300px) {
    
}