


#contact{
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
}


.contact-title{
    font-size: 3rem;
    font-weight: 600;
    padding: 1rem;
   
    margin-top: .5rem;
}
.contact-desc{
    font-weight: 600;
    font-size: 1rem;
    max-width: 51 rem;
    padding: 0 2rem;
  
    max-width: 70rem;
}
.contact-form{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    max-width: 60rem;
}
.name, .email, .number, .event-date, .event-type, .event-venue, .inquiry{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: .5rem;
    padding: .5rem 1rem;
    color: white;
    border: none;
    border-radius: .5rem;
    background-color: rgb(40, 40, 40)
}

.submit-btn{
    background-color: white;
    border: none;
    border-radius: .5rem;
    padding: .75rem 3.5rem;
    margin: 2rem;
}

.social-icon{
object-fit: cover;
height: 3rem;
width: 3rem;
margin: 0 .75rem
}

.links{
    display: flex;
    flex-wrap: wrap;
}


  
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
  }


  @media (max-width: 840px){

    #contact{
      
        max-width: 60rem;
        margin: 0 auto;
        text-align: center;
    }



    .contact-desc{
        font-size:2vw;
        padding: .5rem
    }
  }

 
  @media (max-width: 480px){
    .contact-desc{
        font-size:3vw;
        padding: .5rem
    }
  }