.prices {
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  background-color: rgba(168, 168, 167, 0.3);
  border-radius: 1rem;
}
.pricing{
    color: yellow;
    font-size:3rem;
   margin-top: 3rem;
   padding: .5rem;
   font-weight: 900;
}
.topLine{
    text-align: center;
   margin: 0 7rem;
    align-items: center;
    
}
.info{
    margin: 2rem;
    font-size: 1rem;
    font-weight: 300
}
.cardContainer{
    display: flex;
   flex-wrap: wrap;
    align-items: center;
}

.card {
  color: white;
  opacity: 0.9;
  background-color: rgba(173, 166, 127, 0.3);
  height: 12rem;
  width: 30%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  flex-grow: 1
}

.cardHeader {
  background-color: #8a8254;
  height: 3rem;
  width: 90%;
}

.cardHeader > h4 {
  margin-top: .5rem;
  padding: 0.3rem;
  font-size: 1rem;
  color: white;
  font-weight: 900;
}

.price {
  text-align: center;
  margin: 1rem 0.5rem 0.5rem;
  width: 72%;
  height: 4rem;
  background-color: black;
  border: 1px solid white;
  border-radius:1rem;
}

.price > p {
    font-size: 2rem;
    padding: 0.5rem;
    font-weight: 900;
    margin:  auto 0;
}

.cardBody{
    width: 100%;
}
.cardBody>hr{
    margin: 2rem 1.5rem 0;
    border: .1px solid #8a8254;
   
   
}

@media (max-width: 957px){
    .card{
        width: 28%;
    }
    .price > p {
        font-size: 1.8rem;
      
    }
}

@media (max-width: 840px){
   
    .price > p {
        font-size: 1.6rem;
      
    }
}



@media (max-width: 781px){
    .price > p {
        font-size: 1.5rem;
      
    }
}


@media (max-width: 702px){
    .price{
        width: 80%;

    }
}


@media (max-width: 682px){
    .price > p {
        font-size: 1.2rem;
        margin: auto auto;
      
    }
    .cardHeader{
        height: 4rem;
    }
}


@media (max-width: 415px){
    .cardContainer{
        align-items: center;
    }
    .price > p {
        font-size: 1rem;
     
      
    }
}