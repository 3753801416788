.services{
    overflow:hidden;
    width:100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 2rem auto; ;
}



.skill-title{
    font-size: 3rem;
    font-weight: 600;
    padding: 2rem;
   
    margin-top: 1rem;
}


.skill-sumary{
    font-weight: 600;
    font-size: 1rem;
    max-width: 51 rem;
    padding: 0 2rem;
}





@media (max-width: 560px){
    .skill-summary-container{
        padding: 2rem;
        background-color: #000;
       
        opacity: 0.7;
        
       
      }

}





