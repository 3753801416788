
#events{

    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
    background-color:#3c3d3c;
    border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.event-header{
    color: gold;
    margin-top: .5rem;
    text-align: center;
  
}


.event-title{
font-weight: 900;
}

.event-image{
object-fit: cover;
height: 20rem;
width: 20rem;
margin-right: 2rem;
background: transparent;
}

.event-bar{
display: flex;
margin: 1rem;
padding: 1.5rem 2rem;
border-radius: 0.5rem;

}

@media (max-width: 880px) {
  .event-types > p {
    font-size: 0.8rem;
  }
  .event-image {
    height: 12rem;
    width: 12rem;
  }
}

@media (max-width: 590px) {
  .event-types > p {
    font-size: 0.7rem;
  }
  .event-image {
    height: 6rem;
    width: 6rem;
  }
}



@media (max-width: 480px) {
  .event-types > p {
    font-size: 0.7rem;
  }
  .event-image {
    height: 3rem;
    width: 3rem;
    margin: 0 15px
  }
  .event-bar{
    margin: .5rem;
    padding: 1rem 1.5rem;
  }
}

@media screen and (max-width: 300px) {
    
  .event-image {
  display: none;
  }
}